import Q from 'q';

import {queryHotelOrderDetails, yeepay} from "@/lib/data-service/hotel";
import {STATUS} from "@/lib/deprecated/utils/status";
import PaymentMethodSelector from "@/lib/common-service/getPayModule";

const SUCCESS_CODE = STATUS.success;
export default {
    data() {
        return {
            payRadio: "1",
            orderDetail: {}, //订单详情
            dialogVisible: false,
            warnText: "",
            loading: true,
        };
    },
    created() {
        console.log("pay");
        this.$route.query.orderNo ? this.getList() : "";
    },
    components: {PaymentMethodSelector},
    methods: {
        getList() {
            let data = {
                orderNo: this.$route.query.orderNo,
            };
            queryHotelOrderDetails(data).then((res) => {
                if (res.code === SUCCESS_CODE) {
                    this.loading = false;
                    this.orderDetail = res.orderDetailsResult;
                    this.payInit(res);
                }
            });
        },
        payError() {
            this.dialogVisible = false;
        },
        payInit(_detail = {}) {
            const __this = this;

            let detail = _detail;

            __this.$refs.PaymentMethodSelector.init({
                get_params() {
                    const p = {
                        orderNo: detail.orderDetailsResult.orderNo,
                        clubId: detail.clubId,
                        businessContent: detail.businessContent,
                        companyId: detail.companyId,
                        isUseCredit: detail.creditState || false,
                        subject: detail.orderDetailsResult.hotelNameCn,
                        // orderAmount: Math.floor(detail.orderDetailsResult.orderAmount),
                        orderAmount: detail.orderDetailsResult.orderAmount,
                        clientType: 1,//客户端类型：1.b2b，2.b2c，3.差旅,默认为1
                    };
                    return Q.resolve(p);
                },

                count_down: {
                    enable: false,
                    pay_remaining_seconds: 0, //支付剩余时间，以秒为单位
                },

                //支付成功事件处理方法
                pay_successful_event_handler() {
                    let orderNo = __this.$route.query.orderNo;
                    __this.$router.push({
                        name: "hotel-admin-my-order-list",
                        query: {orderNo: orderNo},
                    });
                    __this.$message({type: "success", message: "支付成功！"});
                },
                //支付失败事件处理方法
                pay_failed_event_handler() {
                    __this.$message({
                        type: "warning",
                        message: "支付失败，请重新支付！",
                    });
                },
            });
        },
        //支付
        submitPay() {
            let data = {
                orderNo: this.orderDetail.orderNo,
                totalFee: this.orderDetail.orderAmount,
                subject:
                    this.orderDetail.hotelNameCn +
                    this.orderDetail.roomTypeCn +
                    this.orderDetail.rateTypeCn,
            };
            yeepay(data).then((res) => {
                console.log(res);
                if (res.code == SUCCESS_CODE) {
                    this.dialogVisible = true;
                    if (res.paymentUrl) {
                        this.warnText = "订单是否已支付";
                        let url = res.paymentUrl;
                        window.open(url);
                    } else {
                        this.warnText = res.msg;
                    }
                }
            });
        },
    },
};
